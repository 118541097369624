import React from 'react';
import styled from 'styled-components';

import { mediaQueries } from '../theme/mediaQueries';

const PageMainHeader = ({ children }) => (
  <StyledPageHeader as="section">{children}</StyledPageHeader>
);

const StyledPageHeader = styled.div`
  background-color: ${(props) => props.theme.colors.transparent};
  padding-top: 1rem;
  padding-bottom: 1rem;
  display: flex;
  justify-content: flex-start;

  ${mediaQueries('lg')`
  padding-top: 2rem;
  padding-bottom: 2rem;
  `}
`;

export default PageMainHeader;
