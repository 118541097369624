import React from 'react';
import { graphql } from 'gatsby';
import { Container } from 'react-bootstrap';

import { mapEdgesToNodes } from '../lib/helpers';

import localize from '../components/localize';
import Layout from '../components/layout';
import SEO from '../components/seo';
import PageHero from '../components/PageHero';
import PageMainHeader from '../components/PageMainHeader';
import PortableText from '../components/portableText';
import AboutTextImgs from '../components/About/AboutTextImgs';
import PageFooter from '../components/PageFooter';

const AboutPage = ({ data, pageContext, path }) => {
  const pageArray = mapEdgesToNodes(data.page);
  const pageInfo = pageArray[0];

  const { textImages, _rawBody } = data.about;

  const splitTextContent = (portableTextArray) => {
    let textPartOne = [];
    let textPartTwo = [];
    let textHalf;

    if (portableTextArray.length % 2 === 0) {
      textHalf = portableTextArray.length / 2;
    } else {
      textHalf = Math.ceil(portableTextArray.length / 2);
    }

    textPartOne = portableTextArray.slice(0, textHalf);
    textPartTwo = portableTextArray.slice(textHalf, portableTextArray.length);

    return [textPartOne, textPartTwo];
  };
  const splitPortableText = splitTextContent(_rawBody);

  return (
    <Layout pageContext={pageContext} path={path} siteTitle={data.site.siteTitle}>
      <SEO title={pageInfo.title} image={pageInfo.heroImg ? pageInfo.heroImg.asset.seoImg : ''} />
      <header>
        <PageHero
          withImage={pageInfo.heroImg ? true : false}
          fluidImg={pageInfo.heroImg ? pageInfo.heroImg.asset.fluid : ''}
          pageTitle={pageInfo.title}
        />
      </header>
      <Container as="section" fluid style={{ marginTop: '4.875rem' }}>
        <Container className="d-flex justify-content-center">
          <PortableText blocks={splitPortableText[0]} />
        </Container>
        {textImages &&
        textImages.firstImage &&
        textImages.firstImage.asset &&
        textImages.secondImage &&
        textImages.secondImage.asset ? (
          <Container>
            <AboutTextImgs
              firstImage={textImages.firstImage}
              secondImage={textImages.secondImage}
            />
          </Container>
        ) : (
          ''
        )}
        <Container className="d-flex justify-content-center">
          <PortableText blocks={splitPortableText[1]} />
        </Container>
      </Container>
      <PageFooter />
    </Layout>
  );
};
// ...GatsbySanityImageFluid
export const query = graphql`
  query AboutPageQuery {
    site: sanitySiteSettings {
      siteTitle
      siteUrl
    }
    page: allSanityPage(filter: { id: { eq: "-4b244c2b-81f1-566a-a75f-51f2c072d0af" } }) {
      edges {
        node {
          id
          title {
            _type
            de
            en
          }
          heroImg {
            asset {
              seoImg: fluid {
                src
              }
              fluid {
                ...GatsbySanityImageFluid
              }
            }
          }
        }
      }
    }
    about: sanityAbout {
      title {
        _type
        de
        en
      }
      _rawBody(resolveReferences: { maxDepth: 5 })
      textImages {
        firstImage {
          alt {
            _type
            de
            en
          }
          asset {
            fluid {
              ...GatsbySanityImageFluid
            }
          }
        }
        secondImage {
          alt {
            _type
            de
            en
          }
          asset {
            fluid {
              ...GatsbySanityImageFluid
            }
          }
        }
      }
    }
  }
`;

export default localize(AboutPage);
