import React from 'react';
import Img from 'gatsby-image';
import styled from 'styled-components';

import { mediaQueries } from '../../theme/mediaQueries';

const AboutTextImgs = ({ firstImage, secondImage }) => {
  const sumAspectRatios = firstImage.asset.fluid.aspectRatio + secondImage.asset.fluid.aspectRatio;

  const calcWidth = (aspectRatio) => {
    return (aspectRatio / sumAspectRatios) * 0.98 * 800;
  };

  const firstWidth = calcWidth(firstImage.asset.fluid.aspectRatio);
  const secondWidth = calcWidth(secondImage.asset.fluid.aspectRatio);

  return (
    <StyledImgsContainer>
      <StyledImgOne
        fluid={firstImage.asset.fluid}
        width={firstWidth}
        imgStyle={{ objectFit: 'contain' }}
        alt={firstImage.alt}
      />
      <StyledImgTwo
        fluid={secondImage.asset.fluid}
        width={secondWidth}
        imgStyle={{ objectFit: 'contain' }}
        alt={secondImage.alt}
      />
    </StyledImgsContainer>
  );
};

const StyledImgsContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 800px;
  margin: 1rem 0 2rem;

  ${mediaQueries('md')`
    flex-direction: row;
    justify-content: center;
  `};
`;

const StyledImgOne = styled(Img)`
  width: 100%;
  height: 100%;

  @media screen and (min-width: 768px) {
    width: ${(props) => props.width}px;
    margin-right: 0.5rem;
  }
`;

const StyledImgTwo = styled(Img)`
  width: 100%;
  height: 100%;

  @media screen and (min-width: 768px) {
    width: ${(props) => props.width}px;
  }
`;

export default AboutTextImgs;
